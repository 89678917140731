.skill-list-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.skill-card {
    flex-grow: 1;
    border: 1px solid var(--grey-color);
}

.skill-card:hover {
    box-shadow: rgba(184, 69, 243, 0.5) 0 5px 15px 0;
}

.skill {
    color: var(--grey-color);
}

.skillset-description {
    padding: 12px;
    border-bottom: 1px solid var(--grey-color);
}

.skill-list-wrapper {
    padding: 12px;
}


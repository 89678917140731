.complete-apps-card {
    max-width: 400px;
    border-radius: 8px;
    border: 1px solid var(--grey-color);
}

.complete-apps-card:hover {
    scale: 1.01;
    box-shadow: rgba(220, 214, 214, 0.5) 0 5px 15px 0;
}

.stack-used {
    padding: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    border-top: 1px solid var(--grey-color);
    border-bottom: 1px solid var(--grey-color);
}

.app-description, .stack-item {
    color: var(--grey-color);
}

.app-screenshot {
    display: inline-block;
    width: 100%;
    height:300px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.app-details {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
}

.card-button {
    margin-top: 8px;
    color: white;
    padding: 8px;
    display: flex;
    gap: 8px;
    justify-content: center;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    align-items: center;
}

.card-button:hover {
    cursor: pointer;
    background: #332936;
}

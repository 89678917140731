.about-me-wrapper{
    padding: 54px;
}

.description-text {
    display: flex;
    flex-direction: column;
    gap: 36px;
    max-width: 700px;
}

.white-text {
    color: white;
}

.about-me-description-wrapper {
    display: flex;
    gap: 100px;
    align-items: center;
    justify-content: space-between;
}

.about-image {
    height: 500px;
}

.title-wrapper {
    display: flex;
    padding-top: 56px;
    justify-content: space-between;
}

.certificate-image {
    position: relative;
    top: -200px;
    height: 600px;
    max-width: 1200px;
}

@media only screen and (max-width: 480px){
    .about-image {
        display: none;
    }
    .about-me-wrapper {
        padding: 16px;
        margin-top: 30px;
    }

    .who-text {
        margin-bottom: 16px;
    }

    .design-image {
        display: none;
    }

    .certificate-image, .french-certificate{
        margin-top: 20px;
        height: 300px;
        max-width: 350px;
        position: relative;
        top: 0;
    }
}

.footer {
    padding: 56px;
    margin-top: 72px;
    border-top: 1px solid var(--grey-color);
}

.footer-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-social {
    display: flex;
    gap: 36px;
}

.copyright {
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 4px;
}

@media only screen and (max-width: 480px) {

    .footer {
        padding: 16px;
    }

    .footer-social {
        gap: 4px;
    }

    .copyright {
        gap: 8px;
    }
}

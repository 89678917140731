.home-image {
    background: url('../../assets/images/landingimage.png') center no-repeat;
    background-size: cover;
    max-width: 100%;
    height: auto;
}

.landing-page-wrapper {
    display: flex;
    margin-top: 62px;
    padding: 24px;
    justify-content: space-around;
}

.right-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.responsive-image {
    max-width: 800px;
    height: auto;
}

.short-description-text {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.short-description-sub {
    color: var(--grey-color);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

.contact-button {
    max-width: 150px;
    margin-top: 24px;
    border-radius: 8px;
    text-align: center;
    border: 1px solid var(--primary-color);
    padding: 8px 16px 8px 16px;
}

.working-icon {
    width: 15px;
    height: 15px;
    margin-right: 8px;
    display: inline-block;
    background: var(--primary-color);
}

.working-row {
    margin-left: 40px;
    width: 450px;
    border-radius: 4px;
    padding: 8px;
    border: 1px solid white;
}

.contact-button:hover {
    cursor: pointer;
    background: #332936;
}

@media only screen and (max-width: 480px) {

    .responsive-image {
        margin-top: 36px;
        max-width: 360px;
    }

    .landing-page-wrapper {
        flex-direction: column;
        padding: 20px;
        margin-top: 20px;
    }

    .contact-button {
        max-width: 100%;
    }

    .working-row {
        margin-left: 20px;
        max-width: 300px;
    }
}


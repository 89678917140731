.contact-page {
    padding: 56px;
}

.contact-wrapper {
    display: flex;
    gap: 72px;
}

.contact-me-section {
    margin-top: 42px;
    padding: 12px;
    border: 1px solid var(--grey-color);
}

.contact-item {
    display: flex;
    gap: 16px;
    margin-top: 24px;
    align-items: center;
}

.contact-image {
    max-width: 300px;
}

@media only screen and (max-width: 480px){
    .contact-page {
        padding: 16px;
    }

    .contact-wrapper {
        flex-direction: column;
    }
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #C778DD;
  --grey-color: #ABB2BF;
}

.primary-color {
  color: #C778DD;
}

.grey-color {
  color: var(--grey-color);
}

.font-size-large {
  font-size: 32px;
}

.link {
  text-decoration: none;
}

.margin-top {
  margin-top: 24px;
}

